// local API call base URL "http://localhost:7000/api/v1"
// staging API call base URL "https://api.auradine.gold/v1/customer"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const env = {
  REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUDIENCE: process.env.REACT_APP_AUDIENCE,
  REACT_FLUX_VISION: process.env.REACT_FLUX_VISION,

  APIPath: {
    jobs: API_BASE_URL + "/jobs",
    user: API_BASE_URL + "/user",
    preference: API_BASE_URL + "/preference",
    handleAPIKey: API_BASE_URL + "/handleAPIKey",
    usertechsupport: API_BASE_URL + "/usertechsupport",
    usertechsupportfilter: API_BASE_URL + "/usertechsupportfilter",
    invite: API_BASE_URL + "/invite",
    domains: API_BASE_URL + "/domains",
    org: API_BASE_URL + "/org",
    orgtechsupport: API_BASE_URL + "/orgtechsupport",
    getdgorgconfig: API_BASE_URL + "/getdgorgconfig",
    postdgorgconfig: API_BASE_URL + "/postdgorgconfig",
    postmultidgorgconfig: API_BASE_URL + "/postmultidgorgconfig",
    otheruser: API_BASE_URL + "/otheruser",
    device: API_BASE_URL + "/device",
    deviceOperation: API_BASE_URL + "/deviceOperation",
    updateRunTimeDB: API_BASE_URL + "/updateRunTimeDB",
    privacy: API_BASE_URL + "/privacy",
    summary: API_BASE_URL + "/summary",
    cloudcommand: API_BASE_URL + "/commands",
    groupcommands: API_BASE_URL + "/groupcommands",
    alertNotificationConfig: API_BASE_URL + "/alertNotificationConfig",
    checkdatabase: API_BASE_URL + "/cmdrespserials",
    validcommands: API_BASE_URL + "/validcmds",
    devicelist: API_BASE_URL + "/devicelist",
    dglist: API_BASE_URL + "/dglist",
    export: API_BASE_URL + "/export",
    isOperationAllowed: API_BASE_URL + "/isOperationAllowed",
    isPoolOperationAllowed: API_BASE_URL + "/isPoolOperationAllowed",
    dgdevices: API_BASE_URL + "/getdgdevices",
    orgdevices: API_BASE_URL + "/getorgdevices",
    dgconfig: API_BASE_URL + "/dgconfig",
    multidgconfig: API_BASE_URL + "/multidgconfig",
    dgtgtTHs: API_BASE_URL + "/dgtgtTHs",
    dgoptTHs: API_BASE_URL + "/dgoptTHs",
    dgtgtPower: API_BASE_URL + "/dgtgtPower",
    dgautoTemparture: API_BASE_URL + "/dgautoTemparture",
    dgisTgtHR: API_BASE_URL + "/dgisTgtHR",
    dgramp: API_BASE_URL + "/dgramp",
    getdginfo: API_BASE_URL + "/getdginfo",
    dgview: API_BASE_URL + "/dgview",
    dgnames: API_BASE_URL + "/dgnames",
    dguseHR: API_BASE_URL + "/dguseHR",
    btc: API_BASE_URL + "/btc",
    manualEfficiencyTune: API_BASE_URL + "/manualEfficiencyTune",
    manualPowerCurtailSchedule: API_BASE_URL + "/manualPowerCurtailSchedule",
    manualPowerCurtailNow: API_BASE_URL + "/manualPowerCurtailNow",
    powerCurtailSchedule: API_BASE_URL + "/powerCurtailSchedule",
    groupCurtailData: API_BASE_URL + "/groupCurtailData",
    autotune: API_BASE_URL + "/autotune",
    onboard: API_BASE_URL + "/onboard",
    importDevices: API_BASE_URL + "/importDevices",
    deregisterDevices: API_BASE_URL + "/deregisterDevices",
    removeOrgTechsupport: API_BASE_URL + "/removeOrgTechsupport",
    removeDevices: API_BASE_URL + "/removeDevices",
    addDGmember: API_BASE_URL + "/addDGmember",
    deleteDGmember: API_BASE_URL + "/deleteDGmember",
    moveDGmember: API_BASE_URL + "/moveDGmember",
    minerTechsupport: API_BASE_URL + "/minerTechsupport",
    getTSJobStatus: API_BASE_URL + "/getTSJobStatus",
    getCommandStatus: API_BASE_URL + "/getCommandStatus",
    addDG: API_BASE_URL + "/addDG",
    deleteDG: API_BASE_URL + "/deleteDG",
    addCustomNames: API_BASE_URL + "/addCustomNames",
    addCSTags: API_BASE_URL + "/addCSTags",
    autoTuneV2Config: API_BASE_URL + "/autoTuneV2Config",
    getAutoTuneV2Config: API_BASE_URL + "/getAutoTuneV2Config",
    getAllAutoTuneV2Config: API_BASE_URL + "/getAllAutoTuneV2Config",
    attachAutoTuneV2Config: API_BASE_URL + "/attachAutoTuneV2Config",
    getEnergyPrice: API_BASE_URL + "/getEnergyPrice",
    getHashPrice: API_BASE_URL + "/getHashPrice",
    desiredAlertIntervalCSTeam: API_BASE_URL + "/desiredAlertIntervalCSTeam",
  },
};

export { env };
